import axios from 'axios';

// https://api.vipower.co.uk/api/
// http://localhost:8000/api/

const api = axios.create({
    baseURL : 'https://api.vipower.co.uk/api/',
    headers : {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
    }
});

api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if(token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default api;