import { Form } from "antd";
import { useState } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";

const useLogin = () => {

    // const BASE_URL = "http://localhost:8000/api/";
    const BASE_URL = "https://api.vipower.co.uk/api/";

    const [form] = Form.useForm();
    const [loginError, setLoginError] = useState(null);
    const [loader, setLoader] = useState(false);

    const navigate = useNavigate();

    // On Successful Form Submit
    const onFormSuccessSubmit = async (values) => {
        setLoader(true);
        // Added a constant username and passoword for testing
        const correctUserName = "jaise";
        const correctPassword = "password";

        // If username and passoword doesn't matches
        // if(values.username !== correctUserName || values.password !== correctPassword) {
        //     setLoginError('Invalid Username or Password');
        //     form.setFields([
        //         {
        //             name : 'username',
        //             errors : values.username !== correctUserName ? ['Invalid username'] : [],
        //         },
        //         {
        //             name : 'password',
        //             errors : values.password !== correctPassword ? ['Invalid Password'] : [],
        //         }
        //     ]);
        // } else {
        //     // setLoginError(null);
        //     // navigate('/main');
            
        // }

        const data = {
            email : values.username,
            password : values.password
        }

        try {
            const response = await axios.post(`${BASE_URL}admin/login`, data);

            if(response.status === 200) {
                const {token, user} = response.data.data;

                localStorage.setItem('token', token);
                localStorage.setItem('user', user);

                navigate('/main');
            }

        } catch(err) {
            console.log(err);
            if(err.response.status === 401) {
                setLoginError(err.response.data.message);
            }
        }
        
        setLoader(false);
    }
    

    return {
        onFormSuccessSubmit,
        loginError,
        form,
        loader,
    }
}

export default useLogin